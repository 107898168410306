(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Vue"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["Vue", "axios"], factory);
	else if(typeof exports === 'object')
		exports["uxc"] = factory(require("Vue"), require("axios"));
	else
		root["uxc"] = root["uxc"] || {}, root["uxc"]["menu"] = factory(root["Vue"], root["axios"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__2740__, __WEBPACK_EXTERNAL_MODULE__3300__) => {
return 